import "../App.css";
import React from 'react'


const Resume = () => {
  return (
    <div class="bg-hartmanblue-200">
      <div class="h-full bg-hartmanblue-200">Resume</div>
    </div>
  )
}

export default Resume